{
  "name": "loopycart-flash-programmer-www",
  "version": "1.0.2",
  "description": "WebUSB interface to Floopy Drive programmer",
  "scripts": {
    "start": "parcel src/index.html",
    "build": "parcel build --no-cache --public-url ./ src/index.html",
    "qa": "parcel src/qa/qa.html",
    "qa-wipe": "parcel src/qa/autowipe.html"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/partlyhuman/loopycart.git"
  },
  "author": "Roger Braunstein",
  "license": "MIT",
  "devDependencies": {
    "@parcel/packager-raw-url": "^2.8.3",
    "@parcel/service-worker": "^2.12.0",
    "@parcel/transformer-inline-string": "2.8.3",
    "@parcel/transformer-sass": "^2.8.3",
    "@parcel/transformer-webmanifest": "^2.8.3",
    "parcel": "^2.8.3"
  },
  "dependencies": {
    "7.css": "^0.16.0",
    "sprintf-js": "^1.1.2"
  },
  "browserslist": [
    "last 2 Chrome versions"
  ]
}
